import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import { navigate } from "gatsby";
import DotaznikLayout from "../../../components/nabidka/dotaznik/DotaznikLayout";
import Pokryti from "../../../assets/img/mapaPokryti.svg";
import styled from "styled-components";
import axios from "axios";
import LoadingKruh from "../../../assets/img/loadingTransparent.svg";


const RegionInner = styled.div`
  .nabidka {

    .loadingImg {
      height: 150px;
    }

    p {
      margin-top: 20px;
    }

    .buttons {
      width: calc(100%);
      display: grid; 
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 35px;

      @media (max-width: 1200px) {
        gap: 20px;
      }

      @media (max-width: 1000px) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      @media (max-width: 700px) {
        grid-template-columns: 1fr 1fr;
        gap: 15px;
      }

      @media (max-width: 500px) {
        grid-template-columns: 1fr;
      }

      button {
        margin-bottom: 0px;

        @media (max-width: 1000px) {
          margin-bottom: 0px;
        }

        @media (max-width: 500px) {
          font-size: 22px;
          padding: 25px 25px;
        }
      }
    }

    .mapa {
      max-width: 750px;
      width: 100%;
      margin-top: 60px;

      h3 {
        font-family: Visuelt-Medium;
        font-size: 18px;
        color: #000000;
        margin-bottom: 30px;
      }

      img {
        display: block;
      }
    }
  }

`;




const Region = ({userData, updateData}) => {
  let uprava = userData;
  const [regiony, setRegiony] = useState();

  function odpoved(co) {
    uprava.region = {
      "otazka": "V jakém regionu byste chtěli aby pohřeb proběhl?",
      "odpoved": co
    }

    updateData(uprava);

    if (co.liteVerze) {
      navigate("/nabidka/dotaznik/lite/");
    }

    else {
      navigate("/nabidka/dotaznik/pohreb/");
    }

  }




  // nastavení správné backend url
  let backendUrl = "";

  if (process.env.NODE_ENV === 'development') {
    backendUrl = 'http://localhost:1337'
  }

  if (process.env.NODE_ENV === 'production') {
    backendUrl = 'https://goodbye-backend.herokuapp.com'
  }



  useEffect(() => {

    axios.post(`${backendUrl}/auth/local`, {
      identifier: 'app@goodbye.cz',
      password: 'Good.Bye123',
    }).then(res => {

      let jwt = res.data.jwt;

      axios.get(`${backendUrl}/regions`, {
        headers: {
          "Authorization": `Bearer ${jwt}`,
          "Content-Type": "application/json"
        },
      })
      .then(res => {
        setRegiony(res.data)
      });
    });
  }, []);



  return (
    <DotaznikLayout phase={2} progress={25}>
      <RegionInner>
        <h1>V jakém regionu byste chtěli, aby pohřeb proběhl?</h1>
        <p>V tuto chvíli fungujeme v regionech uvedených níže. V případě potřeby jsme schopni zajet kamkoli v ČR za poplatek navíc.</p>

        <div className="nabidka">
          <div className="buttons">
            {(regiony) ?
              regiony.map((r) => {
                return (
                  <button key={r.id} onClick={() => odpoved(r)}>{r.name}</button>
                )
              })
            :
              <img src={LoadingKruh} alt="Načítání" className="loadingImg" />
            }
          </div>

          <div className="mapa">
            <h3>Mapa našeho pokrytí v ČR:</h3>
            <img src={Pokryti} alt="pokryti" />
          </div>
        </div>



        {(userData?.uzZemrel) && <>
          {(userData.uzZemrel.odpoved === "Po smrti") ? 
            <Link to="/nabidka/dotaznik/co-nejrychleji/" className="zpet">← Zpět</Link>
          :
            <Link to="/nabidka/dotaznik/stav-blizkeho/" className="zpet">← Zpět</Link>
          }
        </>}
      </RegionInner>
    </DotaznikLayout>
  );
};


export default Region;